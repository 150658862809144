const actions = {

}
const mutations = {

}
const getters = {

}

const state = {
    projectId: 'ecf805f8-5a03-4af4-9882-f70ced27ed94',
    identificationActivity: '312eb927-7191-4609-96f2-7b194cd44126'
};

export default {
  namespaced: true,
  state,
    actions,
    mutations,
    getters
}
